import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";

export interface FontInfo {
  id: number;
  title: string;
  url: string;
}

export const fontsApi = createApi({
  reducerPath: "fontsApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getFonts: builder.query<FontInfo[], void>({
      query: () => ({ url: "fontlist/", method: "GET" }),
    }),
  }),
});

export const { useGetFontsQuery } = fontsApi;
