import { FC } from "react";
import { Modal } from "../Modal";
import { useTranslation } from "react-i18next";

export type ConfirmInfoProps = {
  isShowIcon?: boolean;
  text: string;
  okButtonText?: string;
  cancelButtonText?: string;
};

export type ConfirmProps = ConfirmInfoProps & { isShow: boolean } & {
  onClose: () => void;
  onConfirm: () => void;
};

export const Confirm: FC<ConfirmProps> = ({
  text,
  // okButtonText = {t("common.saveChangesBtn")},
  // cancelButtonText = {t("common.closeBtn")},
  onClose,
  isShow,
  onConfirm,
  ...rest
}) => {
  const { t } = useTranslation();

  const okButtonText = rest.okButtonText?? t("common.saveChangesBtn");
  const cancelButtonText = rest.cancelButtonText?? t("common.closeBtn");

  return (
    <Modal onClose={onClose} isShow={isShow} modalClassName="modal-m">
      <div className="modal-body">
        <p>{text}</p>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          className="text-nowrap btn btn-light-danger font-weight-bold me-6 w-50"
          data-dismiss="modal"
          onClick={onClose}
        >
          {cancelButtonText}
        </button>
        <button
          type="button"
          className="text-nowrap btn btn-danger font-weight-bold w-50"
          onClick={onConfirm}
        >
          {okButtonText}
        </button>
      </div>
    </Modal>
  );
};
