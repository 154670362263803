import { FC } from "react";
import { Modal } from "./Modal";
import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import { check_circle } from "assets/media/icons/custom";

type Props = {
  text: string;
  onClose: () => void;
  onClick: () => void;
  isShow: boolean;
  isShowIcon?: boolean;
  okButtonText?: string;
};

export const Alert: FC<Props> = ({
  text,
  onClose,
  onClick,
  isShow,
  isShowIcon = false,
  okButtonText
}) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} isShow={isShow}>
      <div className="modal-body mb-0">
        <div className="form-modal text-center">
        {isShowIcon === true && (
          <div className="mb-6">
            <SVG src={check_circle} width={60} height={60} />
          </div>
        )}
        <p className="container_title mb-6">{text}</p>
        <button
          type="submit"
          className="btn btn-sm btn-primary w-100"
          data-dismiss="modal"
          onClick={() => {
            onClick();
          }}
        >
          {okButtonText ?? t("common.continueBtn")}
        </button>
        </div>
      </div>
    </Modal>
  );
};
