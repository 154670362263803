import i18n from "i18next";
// import Backend from "i18next-http-backend";
//import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from "react-i18next";
import playerNsEn from "boostinglead-player/src/assets/i18n/en.json";
import playerNsRu from "boostinglead-player/src/assets/i18n/ru.json";
import playerNsUk from "boostinglead-player/src/assets/i18n/uk.json";
import translationsPT from 'boostinglead-player/src/assets/i18n/pt.json';
import translationsIT from 'boostinglead-player/src/assets/i18n/it.json';
import translationsFR from 'boostinglead-player/src/assets/i18n/fr.json';
import translationsPL from 'boostinglead-player/src/assets/i18n/pl.json';
import translationsDE from 'boostinglead-player/src/assets/i18n/de.json';

import En from "./assets/locales/en/translation.json";
import Ru from "./assets/locales/ru/translation.json";
import Uk from "./assets/locales/uk/translation.json";

i18n
  //.use(HttpBackend)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false,
    },
    resources: {
      en: {
        translation: En,
        playerNs: playerNsEn,
      },
      ru: {
        translation: Ru,
        playerNs: playerNsRu,
      },
      uk: {
        translation: Uk,
        playerNs: playerNsUk,
      },
      pt: {
        playerNs: translationsPT
      },
      it: {
        playerNs: translationsIT
      },
      fr: {
        playerNs: translationsFR
      },
      pl: {
        playerNs: translationsPL
      },
      de: {
        playerNs: translationsDE
      }
    },
    defaultNS: "translation",
    lng: "en",
    fallbackLng: "ru",
    debug: true,
    interpolation: { escapeValue: false },
  });

export default i18n;
