import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";
import {
  ConditionEntityInfo,
  sortItemsBySort,
  sortCahceItems,
} from "./questions";
import { FormulaEntity, TextConfig } from "./quizzes";

export const enum CalculationTypeValue {
  "Const" = 1,
  "Formula" = 2,
  "Sum" = 3,
}
export const enum ConditionTypeValue {
  "None" = 0,
  "Logic" = 1,
  "Formula" = 2,
  "Range" = 3,
}

export interface CalculationConfig {
  unit?: number;
  ownFormula?: boolean;
  useSingleValue?: boolean;
  resultValue?: number;
  resultValueFrom?: number;
  resultValueTo?: number;
  formula?: FormulaEntity[];
}
export interface ConditionFormulaConfig {
  formula?: FormulaEntity[];
  resultValueFrom?: number;
  resultValueTo?: number;
  resultValue?: number;
  rangeType?: number;
}
export interface ResultInfo extends ConditionEntityInfo {
  id: number;
  title: string;
  description?: string;
  description_short?: string;
  description_config?: TextConfig;
  print_total?: boolean;
  button?: boolean;
  add_image?: boolean;
  image?: string | null;
  video_link?: string;
  button_text?: string;
  button_link?: string;

  calculation_type?: CalculationTypeValue;
  calculation_config?: CalculationConfig;

  condition_type?: ConditionTypeValue;
  own_formula?: boolean;
  condition_formula_config?: ConditionFormulaConfig;

  sort?: number;
  quiz: number;

  //TODO: Needs fields
  everyone?: boolean;
  condition?: boolean;
}

export const resultsApi = createApi({
  reducerPath: "resultsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Results"],
  endpoints: (builder) => ({
    getResultsByQuizId: builder.query<ResultInfo[], number>({
      query: (quizId) => ({
        url: `quiz/${quizId}/result_list/`,
        method: "GET",
      }),
      transformResponse: (questions?: ResultInfo[]) =>
        sortItemsBySort(questions) as ResultInfo[],
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "Results", id } as const)),
            { type: "Results", id: "LIST" },
          ]
          : [{ type: "Results", id: "LIST" }],
    }),
    getResultById: builder.query<ResultInfo, number>({
      query: (id) => ({ url: `result/${id}`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "Results", id }],
    }),
    createResult: builder.mutation<ResultInfo, Omit<ResultInfo, "id">>({
      query: (data) => ({ url: "result/", method: "POST", data }),
      invalidatesTags: [{ type: "Results", id: "LIST" }],
    }),
    copyResult: builder.mutation<ResultInfo, number>({
      query: (id) => ({ url: "result/copy/", method: "POST", data: { id } }),
      invalidatesTags: [{ type: "Results", id: "LIST" }],
    }),
    updateResult: builder.mutation<
      ResultInfo,
      PartialBy<ResultInfo, "id" | "quiz">
    >({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `result/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted(
        { id, quiz, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const [getResultById, getResultsByQuizId] = updateCacheResult({
          id,
          quiz,
          ...patch,
        });

        const patchResult = dispatch(getResultById);
        const patchListResult = dispatch(getResultsByQuizId);

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchListResult.undo();
          dispatch(resultsApi.util.invalidateTags([{ type: "Results", id }]));
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "Results", id }],
    }),
    updateResultImage: builder.mutation<
      ResultInfo,
      { id: number; formData: FormData }
    >({
      query: (data) => {
        return {
          url: `result/${data.id}/`,
          method: "PATCH",
          data: data.formData,
        };
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { id, quiz, ...patch },
          } = await queryFulfilled;
          const [getResultById, getResultsByQuizId] = updateCacheResult({
            id,
            quiz,
            ...patch,
          });
          dispatch(getResultById);
          dispatch(getResultsByQuizId);
        } catch {
          dispatch(resultsApi.util.invalidateTags([{ type: "Results", id }]));
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "Results", id }],
    }),
    deleteResult: builder.mutation<void, number>({
      query: (id) => ({ url: `result/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [{ type: "Results", id }],
    }),
  }),
});

export const {
  useGetResultsByQuizIdQuery,
  useGetResultByIdQuery,
  useCreateResultMutation,
  useDeleteResultMutation,
  useUpdateResultMutation,
  useUpdateResultImageMutation,
  useCopyResultMutation,
} = resultsApi;

export const updateCacheResult = ({
  id,
  quiz,
  ...patch
}: PartialBy<ResultInfo, "id" | "quiz">) => {
  return [
    resultsApi.util.updateQueryData(
      "getResultById",
      id,
      (draft: ResultInfo) => {
        Object.assign(draft, patch);
      }
    ),
    resultsApi.util.updateQueryData(
      "getResultsByQuizId",
      quiz,
      (draft: ResultInfo[]) => {
        if (patch.sort !== undefined) {
          sortCahceItems(draft, id, patch);
        }
        Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
        if (patch.sort !== undefined) {
          Object.assign(draft, sortItemsBySort(draft));
        }
      }
    ),
  ];
};
