import { createSlice } from "@reduxjs/toolkit";
import { AuthState } from "./models";
import { login, refreshToken, registration, resetPassword, setNewPassword } from "./operations";

const initialState: AuthState = {
  refresh: "",
  access: "",
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(registration.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(registration.fulfilled, (_, { payload }) => {
        return { ...payload, isLoading: false, error: null };
      })
      .addCase(registration.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (_, { payload }) => {
        return { ...payload, isLoading: false, error: null };
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? null;
      })
      .addCase(refreshToken.fulfilled, (state, { payload }) => {
        return { ...state, ...payload };
      })
      .addCase(refreshToken.rejected, (_, { payload }) => {
        return { ...initialState, error: payload } as AuthState;
      })
      .addCase(resetPassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? null;
      })
      .addCase(resetPassword.fulfilled, (_) => {
        return { ...initialState } as AuthState;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(setNewPassword.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? null;
      })
      .addCase(setNewPassword.fulfilled, (_) => {
        console.log(_);
        return { ...initialState } as AuthState;
      })
      .addCase(setNewPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
});

export const authReducer = authSlice.reducer;

export const authAction = authSlice.actions;
