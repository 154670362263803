import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getIsLogin } from "redux/auth/selector";

type Props = {
  component: ReactElement;
  redirectTo?: string;
};

export const PublicRoute: FC<Props> = ({
  component: Component,
  redirectTo,
}) => {
  const isLogin = useSelector(getIsLogin);
  const location = useLocation();

  if (!redirectTo) {
    redirectTo = "/";
    const urlParams = new URLSearchParams(location.search);
    const queryParams: string[] = [];
    urlParams.forEach((value, key) => {
      if (key !== "redirectUrl") {
        queryParams.push(key + "=" + value);
      }

    });
    const to = urlParams.get("redirectUrl");
    if (to) {
      redirectTo = to + (queryParams.length ? "?" + queryParams.join("&") : "");
    }
    // if(location.search)
  }
  return isLogin ? <Navigate to={redirectTo} /> : Component;
};
