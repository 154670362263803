import { FC } from "react";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { book } from "assets/media/icons/custom";
import { useTranslation } from "react-i18next";

const MainMenuProfile: FC = () => {
  const { t } = useTranslation();

  const buttonClass =
    "bg-body btn-color-gray-700 fs-6 fw-semibold btn-active-bg-color-white me-8";
  return (
    <div
      id="kt_app_header_wrapper"
      className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 h-100"
    >
      <div className="submenu-box d-flex align-items-center">
        <NavLink
          to={""}
          end
          className={`btn btn-sm
              ${buttonClass}`}
        >
          <span className="profile-icon svg-icon-6 svg-icon-muted me-3">
            <SVG src={book} />
          </span>
          <span>{t("courses.menu.course")}</span>
        </NavLink>
        {/* <NavLink
          to={"profile"}
          className={`btn btn-sm
              ${buttonClass}`}
        >
          <span className="profile-icon svg-icon-6 svg-icon-muted me-3">
            <SVG src={user_h} />
          </span>
          <span>{t("courses.menu.profile")}</span>
        </NavLink> */}
      </div>
    </div>
  );
};

export { MainMenuProfile };
