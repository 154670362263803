import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { login, registration } from "redux/auth/operations";
import { /*  getErrorMessage, */ getIsLoading } from "redux/auth/selector";

import { useAppDispatch } from "redux/store";
import { useNavigate } from "react-router-dom";
import i18n from "i18n";
import { PublicLayout } from "components/PublicLayout";

const apiBaseUrl = process.env.REACT_APP_BASE_URL;

const initialValues = {
  email: "",
  password: "",
  repeatPassword: "",
  privacyPolicy: false,
};

const RegistrationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("errors.email"))
    .required(i18n.t("errors.required")),
  password: Yup.string().required(i18n.t("errors.required")),
  repeatPassword: Yup.string()
    .required(i18n.t("errors.required"))
    .oneOf([Yup.ref("password"), null], i18n.t("errors.repeatPassword")),
  privacyPolicy: Yup.boolean().oneOf([true], i18n.t("errors.requiredCheck")),
});

export const Registration: FC = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useAppDispatch();
  const isLoading = useSelector(getIsLoading);
  //const errorMessage = useSelector(getErrorMessage);
  const navigate = useNavigate();

  const parsedUrl = new URL(apiBaseUrl ?? "");
  const domain = parsedUrl.origin;
  const privacyPolicyLink =
    domain + "/" + i18n.language + "/u/privacy_policy_page/";

  return (
    <PublicLayout>
      <div className="login-form_box mb-8">
        <h1 className="login-form_title">{t("registration.title")}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={RegistrationSchema}
          onSubmit={async (
            { email, password, repeatPassword },
            { setErrors }
          ) => {
            const res = await dispatch(
              registration({
                username: email,
                email,
                password,
                language: i18n.language,
              }) as unknown as AnyAction
            );
            if (res.error) setErrors(res.payload);
            else {
              const res = await dispatch(
                login({ email, password }) as unknown as AnyAction
              );
              if (res.error)
                setErrors({ password: res.payload });
              else
                navigate("/");
            }
            // setErrors({password: "Пароль может содержать только латинские буквы и цифры"})
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            touched,
            errors,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="login-form"
                id="kt_login_singin_form"
                action=""
                noValidate={true}
              >
                <div>
                  <label
                    className={
                      errors.email && touched.email
                        ? "form-label error"
                        : "form-label"
                    }
                  >
                    {t("registration.yourEmail")}
                  </label>
                  <input
                    className={
                      errors.email && touched.email
                        ? "form-control error"
                        : "form-control"
                    }
                    type="email"
                    name="email"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                  <ErrorMessage
                    name="email"
                    render={(message) => (
                      <div
                        data-field="email"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <div>
                  <label
                    className={
                      errors.password && touched.password
                        ? "form-label error"
                        : "form-label"
                    }
                  >
                    {t("registration.enterPassword")}
                  </label>
                  <input
                    className={
                      errors.password && touched.password
                        ? "form-control error"
                        : "form-control"
                    }
                    type="password"
                    name="password"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <ErrorMessage
                    name="password"
                    render={(message) => (
                      <div
                        data-field="password"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <div>
                  <label
                    className={
                      errors.repeatPassword && touched.repeatPassword
                        ? "form-label error"
                        : "form-label"
                    }
                  >
                    {t("registration.repeatPassword")}
                  </label>
                  <input
                    className={
                      errors.repeatPassword && touched.repeatPassword
                        ? "form-control error"
                        : "form-control"
                    }
                    type="password"
                    name="repeatPassword"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.repeatPassword}
                  />
                  <ErrorMessage
                    name="repeatPassword"
                    render={(message) => (
                      <div
                        data-field="password"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <div
                  className={`d-flex flex-column align-items-start pt-3 mb-3`}
                >
                  <label className="form-check form-check-solid mb-7">
                    <Field
                      className="form-check-input"
                      type="checkbox"
                      name="privacyPolicy"
                    />
                    <span className="fw-normal text-black fs-8 opacity-75">
                      {t("registration.privacyPolicy1")}
                      <a
                        href={privacyPolicyLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {t("registration.privacyPolicy2")}
                      </a>
                    </span>
                  </label>
                  <ErrorMessage
                    name="privacyPolicy"
                    render={(message) => (
                      <div
                        data-field="privacyPolicy"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  id="kt_login_singin_form_submit_button"
                  className="btn btn-sm btn-primary font-weight-bolder font-size-h6 mt-0 w-100"
                >
                  {t("registration.registerBtn")}
                </button>
              </form>
            );
          }}
        </Formik>
      </div>

      <div className="login-form_box">
        <h2 className="login-form_title-second">
          {t("registration.titleSecond")}
        </h2>
        <button
          type="submit"
          disabled={isLoading}
          id="kt_login_singin_form_submit_button"
          className="btn btn-sm btn-primary font-weight-bolder font-size-h6 w-100 mb-3"
          onClick={() => navigate("/login")}
        >
          {t("registration.loginBtn")}
        </button>
        {/* <button
                className="form-button btn btn-sm bg-body btn-light-primary w-100"
                type="button"
              >
                <span>
                  <SVG className="" src={google} width={16} height={16} />
                </span>
                <span>{t("registration.googleBtn")}</span>
              </button> */}
      </div>
    </PublicLayout>
  );
};
