import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationTelegram {
  id: number
  quiz: number
  telegram_id: string
  is_enabled: boolean
}

export const integrationTelegramApi = createApi({
  reducerPath: "integrationTelegramApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["IntegrationTelegram"],
  endpoints: (builder) => ({
    getTelegramByQuizId: builder.query<IntegrationTelegram, number>({
      query: (quizId) => ({ url: `telegram-integration/${quizId}/`, method: "GET" }),
      providesTags: (result, error, id) => {
        return [{ type: "IntegrationTelegram", id }];
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          if (err.error && err.error.status === 404) {
            dispatch(integrationTelegramApi.util.upsertQueryData('getTelegramByQuizId', arg, null as any));
          }
        }
      },
    }),
    createTelegram: builder.mutation<
      IntegrationTelegram,
      Omit<IntegrationTelegram, "id">
    >({
      query: (data) => ({ url: "telegram-integration/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(integrationTelegramApi.util.invalidateTags([{ type: "IntegrationTelegram", id: data.quiz }]));
      },
    }),
    updateTelegram: builder.mutation<
      IntegrationTelegram,
      PartialBy<IntegrationTelegram, "quiz">
    >({
      query: (data) => {
        const { id, quiz, ...body } = data;
        return { url: `telegram-integration/${quiz}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(updateCacheSalesDrive({ id, quiz, ...patch }));
        try {
          const result = await queryFulfilled;
          dispatch(updateCacheSalesDrive({ ...result.data }))
        } catch {
          patchResult.undo();
          dispatch(
            integrationTelegramApi.util.invalidateTags([{ type: "IntegrationTelegram", id: quiz }])
          );
        }
      },
    }),
    deleteTelegram: builder.mutation<void, number>({
      query: (quizId) => ({ url: `telegram-integration/${quizId}/`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => {
        return [{ type: "IntegrationTelegram", id }];
      },
    }),
  }),
});

export const {
  useGetTelegramByQuizIdQuery,
  useCreateTelegramMutation,
  useUpdateTelegramMutation,
  useDeleteTelegramMutation,

} = integrationTelegramApi;

export const updateCacheSalesDrive = ({
  id,
  quiz,
  ...patch
}: PartialBy<IntegrationTelegram, "quiz">) => {
  return integrationTelegramApi.util.updateQueryData(
    "getTelegramByQuizId",
    quiz,
    (draft: Partial<IntegrationTelegram>) => {
      Object.assign(draft, patch);
    }
  );
};
