import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";

export interface CurrencyInfo {
  id: number;
  title: string;
  country: string;
}

export const currenciesApi = createApi({
  reducerPath: "currenciesApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getCurrencies: builder.query<CurrencyInfo[], void>({
      query: () => ({ url: "currencylist/", method: "GET" }),
    }),
  }),
});

export const { useGetCurrenciesQuery } = currenciesApi;
