import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useRef,
  useState,
  useContext,
} from "react";
import { Confirm, ConfirmInfoProps } from "./Confirm";
import { Alert } from "../Alert";

const ConfirmDialog = createContext<
  (props: ContextProps, type?: "alert" | "confirm") => Promise<boolean>
>(() => Promise.resolve(false));

type Props = {
  children: ReactNode;
};

type ContextProps = ConfirmInfoProps;

export const ConfirmDialogProvider: FC<Props> = ({ children }) => {
  const [state, setState] = useState<ConfirmInfoProps & { isShow: boolean }>({
    isShow: false,
    text: "",
  });

  const [type, setType] = useState<"alert" | "confirm">("confirm");
  const fn = useRef<(choice: boolean) => void>();
  const confirm = useCallback(
    (props: ContextProps, type?: "alert" | "confirm") => {
      setType(type ?? "confirm");
      return new Promise<boolean>((resolve: any) => {
        setState({ ...props, isShow: true });
        fn.current = (choice: boolean) => {
          resolve(choice);
          setState({ ...props, isShow: false });
        };
      });
    },
    [setState]
  );

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      {type === "alert" ? (
        <Alert
          {...state}
          onClose={() => fn.current && fn.current(false)}
          onClick={() => fn.current && fn.current(true)}
        />
      ) : (
        <Confirm
          {...state}
          onClose={() => fn.current && fn.current(false)}
          onConfirm={() => fn.current && fn.current(true)}
        />
      )}
    </ConfirmDialog.Provider>
  );
};

export function useConfirm() {
  const context = useContext(ConfirmDialog);
  return context;
}
