import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { userInfoApi } from "./userInfo";
import { tariffsApi } from "./tariffs";

export interface FeedbackServiceInfo {
  id: number;
  title: string;
  link: string;
  logo: string;
}

export enum PaymentRequestType {
  PayPal = 1,
  Card = 2
}
export type PaymentRequestInfo<T extends PaymentCardRequestInfo | PaymentPaypalRequestInfo> = T & {
  type: PaymentRequestType
}
export interface PaymentCardRequestInfo {
  card_number?: string;
  cardholder_name?: string;
  withdrawal_amount: number
}
export interface PaymentPaypalRequestInfo {
  email: string
  withdrawal_amount: number
}

export enum PaymentMethod {
  Card = 1,
  PayPal = 14
}
export interface TariffQuery {
  payment_method: PaymentMethod,
  period_of_months: 1 | 6 | 12,
  tariff: number
}
export interface UpBalanceQuery {
  payment_method: PaymentMethod,
  amount: number
}
export interface BuyTariffFromBalanceInfo {
  tariff: number,
  period: number
}

export const otherApi = createApi({
  reducerPath: "otherApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createSupport: builder.mutation<void, { email: string; message: string }>({
      query: (data) => ({ url: "support/", method: "POST", data }),
    }),
    getFeedbackServices: builder.query<FeedbackServiceInfo[], void>({
      query: () => ({ url: "service-feedback-list/", method: "GET" }),
    }),
    createFeedback: builder.mutation<void, { service: number; link: string }>({
      query: (data) => ({ url: "create-feedback/", method: "POST", data }),
    }),
    transferReferralBalance: builder.mutation<void, { transfer_referral_balance_count: number }>({
      query: (data) => ({ url: "transfer_referral_balance/", method: "PUT", data }),
    }),
    createReferralPaymentRequest: builder.mutation<void, PaymentRequestInfo<PaymentCardRequestInfo | PaymentPaypalRequestInfo>>({
      query: (data) => ({ url: "referral_payment_request/", method: "POST", data: { data } }),
    }),
    setSessionForPlayer: builder.mutation<{ session: string }, number>({
      query: (quizId) => ({ url: '/client-sessions/', method: "POST", data: { quiz: quizId, service_session: true } }),
    }),
    getTariffLinks: builder.query<string, TariffQuery | UpBalanceQuery>({
      query: (params) => ({ url: "tarifflinks/", params, method: "GET" }),
      transformResponse: (rawResult: { link: string }[]) => {
        const [result] = rawResult;
        return result.link;
      },
    }),
    getValueByKey: builder.query<{ [key: string]: string }, string[]>({
      query: (keys) => ({ url: `key_value/${keys ? `?${keys.map(item => "keys=" + item).join('&')}` : ''}`/* , params: { keys } */, method: "GET" })
    }),
    buyTariffFromBalance: builder.mutation<any, BuyTariffFromBalanceInfo>({
      query: (data) => ({ url: "buy-tariff-from-balance/", data, method: "POST" }),
      async onQueryStarted(
        data,
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(userInfoApi.util.invalidateTags([{ type: "UserInfo", id: "LIST" }]));
          dispatch(tariffsApi.util.invalidateTags([{ type: "TariffUserInfo", id: "LIST" }]));
        } catch {
          dispatch(userInfoApi.util.invalidateTags([{ type: "UserInfo", id: "LIST" }]));
          dispatch(tariffsApi.util.invalidateTags([{ type: "TariffUserInfo", id: "LIST" }]));

        }
      }
    })
  })

});

export const {
  useGetFeedbackServicesQuery,
  useCreateSupportMutation,
  useCreateFeedbackMutation,
  useTransferReferralBalanceMutation,
  useCreateReferralPaymentRequestMutation,
  useSetSessionForPlayerMutation,
  useGetTariffLinksQuery,
  useGetValueByKeyQuery,
  useBuyTariffFromBalanceMutation
} = otherApi;
