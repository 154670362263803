import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface UserInfo {
  id: number;
  balance: number;
  country: number;
  email: string;
  photo: string;
  speciality: number;
  username: string;
  language: number;
  language_code: string;
  tariff?: number;
  tariff_end_date?: number;
  support_id: number;
  referral_balance: number,
  count_referrals: number,
  referral_percentage: number
}

export const userInfoApi = createApi({
  reducerPath: "userInfoApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["UserInfo"],
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfo, void>({
      query: () => ({ url: "userinfo/", method: "GET" }),
      transformResponse: (rawResult: UserInfo[]) => {
        const [result] = rawResult;
        return result;
      },
      keepUnusedDataFor: 60,
      providesTags: [{ type: "UserInfo", id: "LIST" }],
    }),
    updateUserInfo: builder.mutation<UserInfo, PartialBy<UserInfo, "id">>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `userinfo/${data.id}/`, method: "PATCH", data: body };
      },
      invalidatesTags: [{ type: "UserInfo", id: "LIST" }],
    }),
    changePassword: builder.mutation<
      void,
      { old_password: string; new_password1: string; new_password2: string }
    >({
      query: (data) => ({ url: "change-password/", method: "POST", data }),
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useUpdateUserInfoMutation,
  useChangePasswordMutation,
} = userInfoApi;
