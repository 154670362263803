import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";



export interface Bitrix24Info {
  id: number
  quiz: number
  link: string
  is_enabled: boolean,
  assigned?: number
}

export const bitrix24Api = createApi({
  reducerPath: "bitrix24Api",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Bitrix24"],
  endpoints: (builder) => ({
    getBitrix24ByQuizId: builder.query<Bitrix24Info, number>({
      query: (quizId) => ({ url: `bitrix24/${quizId}/`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "Bitrix24", id }],
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          if (err.error && err.error.status === 404) {
            dispatch(bitrix24Api.util.upsertQueryData('getBitrix24ByQuizId', arg, null as any));
          }
        }
      },
    }),
    createBitrix24: builder.mutation<
      Bitrix24Info,
      Omit<Bitrix24Info, "id">
    >({
      query: (data) => ({ url: "bitrix24/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const {data} = await queryFulfilled;
        dispatch(bitrix24Api.util.invalidateTags([{ type: "Bitrix24", id: data.quiz }]));
      },
      //invalidatesTags: [{ type: "Bitrix24", id: "LIST" }],
    }),
    updateBitrix24: builder.mutation<
      Bitrix24Info,
      PartialBy<Bitrix24Info, "quiz">
    >({
      query: (data) => {
        const { id, quiz, ...body } = data;
        return { url: `bitrix24/${quiz}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(updateCacheBitrix({ quiz, ...patch }));
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(
            bitrix24Api.util.invalidateTags([{ type: "Bitrix24", id: quiz }])
          );
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "QuizConfigs", id }],
    }),
    deleteBitrix24: builder.mutation<void, number>({
      query: (quizId) => ({ url: `bitrix24/${quizId}/`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [{ type: "Bitrix24", id }],
    }),
  }),
});

export const {
  useGetBitrix24ByQuizIdQuery,
  useCreateBitrix24Mutation,
  useUpdateBitrix24Mutation,
  useDeleteBitrix24Mutation,

} = bitrix24Api;

export const updateCacheBitrix = ({
  quiz,
  ...patch
}: PartialBy<Bitrix24Info, "quiz">) => {
  return bitrix24Api.util.updateQueryData(
    "getBitrix24ByQuizId",
    quiz,
    (draft: Partial<Bitrix24Info>) => {
      Object.assign(draft, patch);
    }
  );
};
