import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";
import { TextConfig } from "./quizzes";

export enum AdditionType {
  Bonus = 1,
  Discount = 2,
  Consultant = 3,
}
export interface AdditionBonusConfig {
  link?: string;
}
export interface AdditionDiscountConfig {
  typeDiscount: number;
  unitDiscount: number;
  discountValue: number;
}

export interface AdditionConsultantConfig {
  jobTitle?: string;
  description?: string;
  descriptionConfig?: TextConfig;
}
export interface AdditionInfo {
  id: number;
  type: AdditionType;
  title?: string;
  image?: string | null;
  config:
    | AdditionBonusConfig
    | AdditionDiscountConfig
    | AdditionConsultantConfig;
  datetime?: string;
  quiz: number;
}

export const additionsApi = createApi({
  reducerPath: "additionsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Additions"],
  endpoints: (builder) => ({
    getAdditionsByQuizId: builder.query<AdditionInfo[], number>({
      query: (quizId) => ({ url: `addition/${quizId}/by_quiz`, method: "GET" }),
      // transformResponse: (res: AdditionInfo[]) => {
      //   res.sort((a, b) => b.type - a.type);
      //   return res;
      // },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Additions", id } as const)),
              { type: "Additions", id: "LIST" },
            ]
          : [{ type: "Additions", id: "LIST" }],
    }),
    // getAdditionById: builder.query<AdditionInfo, number>({
    //   query: (id) => ({ url: `addition/${id}`, method: "GET" }),
    //   providesTags: (result, error, id) => [{ type: "Additions", id }],
    // }),
    createAddition: builder.mutation<AdditionInfo, Omit<AdditionInfo, "id">>({
      query: (data) => ({ url: "addition/", method: "POST", data }),
      invalidatesTags: [{ type: "Additions", id: "LIST" }],
    }),
    updateAddition: builder.mutation<
      AdditionInfo,
      PartialBy<AdditionInfo, "id" | "quiz">
    >({
      query: (data) => {
       // console.log();
        const { id, ...body } = data;
        return { url: `addition/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted(
        { id, quiz, ...patch },
        { dispatch, queryFulfilled }
      ) {
        // const patchResult = dispatch(
        //   additionsApi.util.updateQueryData(
        //     "getAdditionById",
        //     id,
        //     (draft: AdditionInfo) => {
        //       Object.assign(draft, patch);
        //     }
        //   )
          
        // );
        const patchListResult = dispatch(
          updateCacheAddition({ id, quiz, ...patch })
        );

        try {
          await queryFulfilled;
        } catch {
          //patchResult.undo();
          patchListResult.undo();
          dispatch(additionsApi.util.invalidateTags([{ type: "Additions", id }]));

        }
      },
      // invalidatesTags: (result, error, { id }) => [{ type: "Additions", id }],
    }),
    updateAdditionImage: builder.mutation<
      AdditionInfo,
      { id: number; formData: FormData }
    >({
      query: (data) => {
        return {
          url: `addition/${data.id}/`,
          method: "PATCH",
          data: data.formData,
        };
      },
      async onQueryStarted(
        { id, ...patch },
        { dispatch, queryFulfilled }
      ) {

        try {
          const  {data} = await queryFulfilled;
          dispatch(
            updateCacheAddition({ ...data })
          );
        } catch {
          dispatch(additionsApi.util.invalidateTags([{ type: "Additions", id }]));
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "Additions", id }],
    }),
    deleteAddition: builder.mutation<void, number>({
      query: (id) => ({ url: `addition/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [{ type: "Additions", id }],
    }),
  }),
});

export const {
  useGetAdditionsByQuizIdQuery,
  //useGetAdditionByIdQuery,
  useCreateAdditionMutation,
  useDeleteAdditionMutation,
  useUpdateAdditionMutation,
  useUpdateAdditionImageMutation,
} = additionsApi;

export const updateCacheAddition = ({
  id,
  quiz,
  ...patch
}: PartialBy<AdditionInfo, "id" | "quiz">) => {
  return additionsApi.util.updateQueryData(
    "getAdditionsByQuizId",
    quiz,
    (draft: AdditionInfo[]) => {
      Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
    }
  )
  
;
};
