import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const MainMenuBalance: FC = () => {
  const { t } = useTranslation();
  const buttonClass =
    "bg-body btn-color-gray-700 fs-6 fw-semibold btn-active-bg-color-white me-8";
  return (
    <div
      id="kt_app_header_wrapper"
      className="d-flex align-items-stretch justify-content-between flex-lg-grow-1 h-100"
    >
      <div className="submenu-box d-flex align-items-center">
        <NavLink
          to={""}
          end
          className={`btn btn-sm
              ${buttonClass}`}
          
        >
          <span>{t("balance.menu.pay")}</span>
        </NavLink>
        <NavLink
          to={"my-rate"}
          end
          className={`btn btn-sm
              ${buttonClass}`}
          
        >
          <span>{t("balance.menu.tariff")}</span>
        </NavLink>
      </div>
    </div>
  );
};

export { MainMenuBalance };
