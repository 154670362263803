import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export enum FormFieldType {
  Title = 1,
  Phone = 2,
  Email = 3,
  External = 4,
  External2 = 5,
}

export interface FormFieldInfo {
  id: number;
  required: boolean;
  title: string;
  type_field: FormFieldType;
  quiz: number;
}

export const formFieldsApi = createApi({
  reducerPath: "formFieldsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["FormFields"],
  endpoints: (builder) => ({
    getFormFieldsByQuizId: builder.query<FormFieldInfo[], number>({
      query: (quizId) => ({ url: `quiz/${quizId}/formfield_list/`, method: "GET" }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "FormFields", id } as const)),
              { type: "FormFields", id: "LIST" },
            ]
          : [{ type: "FormFields", id: "LIST" }],
    }),
    getFormFieldById: builder.query<FormFieldInfo, number>({
      query: (id) => ({ url: `formfield/${id}`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "FormFields", id }],
    }),
    createFormField: builder.mutation<FormFieldInfo, Omit<FormFieldInfo, "id">>(
      {
        query: (data) => ({ url: "formfield/", method: "POST", data }),
        invalidatesTags: [{ type: "FormFields", id: "LIST" }],
      }
    ),
    updateFormField: builder.mutation<
      FormFieldInfo,
      PartialBy<FormFieldInfo, "id" | "quiz">
    >({
      query: (data) => {
        //console.log();
        const { id, ...body } = data;
        return { url: `formfield/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted(
        { id, quiz, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const [getFormFieldById, getFormFieldsByQuizId] = updateCacheFormField({
          id,
          quiz,
          ...patch,
        });
        const patchResult = dispatch(getFormFieldById);
        const patchListResult = dispatch(getFormFieldsByQuizId);

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchListResult.undo();
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: "FormFields", id }],
    }),
    deleteFormField: builder.mutation<void, number>({
      query: (id) => ({ url: `formfield/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [{ type: "FormFields", id }],
    }),
  }),
});

export const {
  useGetFormFieldsByQuizIdQuery,
  useGetFormFieldByIdQuery,
  useCreateFormFieldMutation,
  useDeleteFormFieldMutation,
  useUpdateFormFieldMutation,
} = formFieldsApi;


export const updateCacheFormField = ({
  id,
  quiz,
  ...patch
}: PartialBy<FormFieldInfo, "id" | "quiz">) => {
  return [
    formFieldsApi.util.updateQueryData(
      "getFormFieldById",
      id,
      (draft: FormFieldInfo) => {
        Object.assign(draft, patch);
      }
    ),
    formFieldsApi.util.updateQueryData(
      "getFormFieldsByQuizId",
      quiz,
      (draft: FormFieldInfo[]) => {
        Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
      }
    ),
  ];
};
