import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";


export interface IntegrationEmailInfo {
  id: number;
  quiz: number;
  email: string;
}


export const integrationEmailsApi = createApi({
  reducerPath: "integrationEmailsApi",
  tagTypes: ["IntegrationEmails"],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getIntegrationEmails: builder.query<IntegrationEmailInfo[], number>({
      query: (quizId) => ({ url: `integration/email/list/${quizId}`, method: "GET" }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "IntegrationEmails", id } as const)),
            { type: "IntegrationEmails", id: "LIST" },
          ]
          : [{ type: "IntegrationEmails", id: "LIST" }],
    }),
    createIntegrationEmail: builder.mutation<IntegrationEmailInfo, Omit<IntegrationEmailInfo, "id">>({
      query: (data) => ({ url: "integration/email/create/", method: "POST", data }),
      invalidatesTags: [{ type: "IntegrationEmails", id: "LIST" }],

    }),
    deleteIntegrationEmail: builder.mutation<void, { id: number, quiz: number }>({
      query: ({ id }) => ({ url: `integration/email/delete/${id}`, method: "DELETE" }),
      async onQueryStarted({ id, quiz }, { dispatch, queryFulfilled }) {
        dispatch(
          integrationEmailsApi.util.updateQueryData(
            "getIntegrationEmails",
            quiz,
            (draft: IntegrationEmailInfo[]) => {
              return draft.filter((item) => item.id !== id);
            }
          )
        );
        //questionsApi.util.patchQueryData
        await queryFulfilled;
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "IntegrationEmails", id },
        { type: "IntegrationEmails", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useCreateIntegrationEmailMutation,
  useGetIntegrationEmailsQuery,
  useDeleteIntegrationEmailMutation
} = integrationEmailsApi;
