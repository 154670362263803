import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";
import { questionsApi/* , updateCacheQuestion */ } from "./questions";

export interface ConditionConfigForAnswers {
  everyone: boolean;
}
export interface ConditionConfigForText extends ConditionConfigForAnswers {
  includes: boolean;
  words: string[];
}
export interface ConditionConfigForRatingAndSlider {
  type?: number;
  value?: number;
  fromValue?: number;
  toValue?: number;
}
type ConditionConfig =
  | ConditionConfigForAnswers
  | ConditionConfigForRatingAndSlider
  | ConditionConfigForText;

export interface ConditionInfo {
  id: number;
  everyone: boolean;
  condition_config?: ConditionConfig;

  question_condition?: number;
  answer?: number[];
}

export interface QuestionConditionInfo extends ConditionInfo {
  question: number;
}

export const questionConditionsApi = createApi({
  reducerPath: "questionConditionsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["QuestionConditions"],
  endpoints: (builder) => ({
    getQuestionConditionsByQuestionId: builder.query<
      QuestionConditionInfo[],
      number
    >({
      query: (questionId) => ({
        url: `questionconditionlist/${questionId}/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: "QuestionConditions", id } as const)
              ),
              { type: "QuestionConditions", id: "LIST" },
            ]
          : [{ type: "QuestionConditions", id: "LIST" }],
    }),
    getQuestionConditionById: builder.query<QuestionConditionInfo, number>({
      query: (id) => ({ url: `questioncondition/${id}/`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "QuestionConditions", id }],
    }),
    createQuestionCondition: builder.mutation<
      QuestionConditionInfo,
      Omit<QuestionConditionInfo, "id">
    >({
      query: (data) => ({ url: "questioncondition/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: created } = await queryFulfilled;
          const [getQuestionConditionById] =
            updateCacheQuestionCondition(created);
          dispatch(getQuestionConditionById);

          dispatch(questionsApi.util.invalidateTags([{ type: "Questions", id: "LIST" }]));
          //console.log(questionsApi);

          //updateCacheQuestion({ id: created.question });

          dispatch(
            questionConditionsApi.util.updateQueryData(
              "getQuestionConditionsByQuestionId",
              created.question,
              (draft: QuestionConditionInfo[]) => {
                draft.push(created);
                //console.log("Update cache",[...draft.map((item) => ({ ...item }))]);
                //Object.assign(draft, sortItemsBySort(draft));
              }
            )
          );
        } catch {
          dispatch(
            questionConditionsApi.util.invalidateTags([
              { type: "QuestionConditions", id: "LIST" },
            ])
          );
        }
      },
      //invalidatesTags: [{ type: "QuestionConditions", id: "LIST" }],
    }),
    updateQuestionCondition: builder.mutation<
      QuestionConditionInfo,
      PartialBy<QuestionConditionInfo, "id" | "question">
    >({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `questioncondition/${data.id}/`,
          method: "PATCH",
          data: body,
        };
      },
      async onQueryStarted(
        { id, question, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const [getQuestionConditionById, getQuestionConditionsByQuestionId] =
          updateCacheQuestionCondition({
            id,
            question,
            ...patch,
          });
        let patchResult = dispatch(getQuestionConditionById);
        let patchListResult = dispatch(getQuestionConditionsByQuestionId);

        try {
          const { data: updateData } = await queryFulfilled;
          const [getQuestionConditionById, getQuestionConditionsByQuestionId] =
            updateCacheQuestionCondition(updateData);
          patchResult = dispatch(getQuestionConditionById);
          patchListResult = dispatch(getQuestionConditionsByQuestionId);
        } catch {
          patchResult.undo();
          patchListResult.undo();
          dispatch(
            questionConditionsApi.util.invalidateTags([
              { type: "QuestionConditions", id },
            ])
          );
        }
      },
      // invalidatesTags: (result, error, { id }) => [
      //   { type: "QuestionConditions", id },
      // ],
    }),
    deleteQuestionCondition: builder.mutation<
      void,
      { id: number; question: number }
    >({
      query: ({ id, question }) => ({
        url: `questioncondition/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted({ id, question }, { dispatch, queryFulfilled }) {
        dispatch(
          questionConditionsApi.util.updateQueryData(
            "getQuestionConditionsByQuestionId",
            question,
            (draft: QuestionConditionInfo[]) => {
              return draft.filter((item) => item.id !== id);
            }
          )
        );

        await queryFulfilled;
        dispatch(questionsApi.util.invalidateTags([{ type: "Questions", id: "LIST" }]));
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "QuestionConditions", id },
        { type: "QuestionConditions", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetQuestionConditionsByQuestionIdQuery,
  useGetQuestionConditionByIdQuery,
  useCreateQuestionConditionMutation,
  useDeleteQuestionConditionMutation,
  useUpdateQuestionConditionMutation,
} = questionConditionsApi;

export const updateCacheQuestionCondition = ({
  id,
  question,
  ...patch
}: PartialBy<QuestionConditionInfo, "id" | "question">) => {
  return [
    questionConditionsApi.util.updateQueryData(
      "getQuestionConditionById",
      id,
      (draft: QuestionConditionInfo) => {
        console.log({ ...draft });
        Object.assign(draft, patch);
      }
    ),
    questionConditionsApi.util.updateQueryData(
      "getQuestionConditionsByQuestionId",
      question,
      (draft: QuestionConditionInfo[]) => {
        Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
      }
    ),
  ];
};
