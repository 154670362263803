import axios, { AxiosRequestConfig } from "axios";
import { getTokens } from "redux/auth/selector";
import { store } from "redux/store";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { refreshToken } from "redux/auth/operations";
import { AnyAction } from "redux";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const setAuthToken = (req: AxiosRequestConfig<any>, accessToken: string) => {
  req.headers = {
    Authorization: `Bearer ${accessToken}`,
  };
};

axiosInstance.interceptors.request.use(async (req) => {
  let accessToken = getTokens(store.getState()).access;
  const token = jwtDecode<JwtPayload>(accessToken);
  const currentTime = Date.now() / 1000;
  if ((token.exp ?? currentTime) <= currentTime) {
    await store.dispatch(refreshToken() as unknown as AnyAction);
    accessToken = getTokens(store.getState()).access;
  }

  setAuthToken(req, accessToken);
  return req;
});
