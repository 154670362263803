import { /* google, */ login_bg1, login_bg2 } from "assets/media/icons/custom";
import { FC, ReactNode } from "react";
import SVG from "react-inlinesvg";

type Props = {
    children: ReactNode;
}

export const PublicLayout: FC<Props> = ({children}) => {
  return (
    <>
      <div
        className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid bg-primary"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <div className="login-thumba_box">
          <div className="login-thumba">
          <SVG className="" src={login_bg1} />
          </div>
          <div className="login-thumba">
          <SVG className="" src={login_bg2} />
          </div>
        </div>

        <div className="login-content">
          <div className="w-100">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
