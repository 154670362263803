import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";
import { TextConfig } from "./quizzes";
import { questionConditionsApi } from "./questionConditions";
import { resultConditionsApi } from "./resultConditions";

export interface QuestionConfig { }

export enum QuestionTypeValue {
  "LIST" = 1,
  "CARDS" = 2,
  "SELECT" = 3,
  "TEXT" = 4,
  "RATING" = 5,
  "DATE" = 6,
  "SLIDER" = 7,
}

export interface ConditionEntityInfo {
  id: number;
  everyone?: boolean;
  quiz: number;
}

export interface QuestionInfo extends ConditionEntityInfo {
  id: number;
  title: string;
  title_config?: TextConfig;
  description?: string;
  description_config?: TextConfig;
  type: QuestionTypeValue;
  sort?: null | any;
  required: boolean;
  config?: QuestionConfig;
  quiz: number;
  condition?: [];
  // everyone?: boolean;
  //user?: number | null;
}

export const sortItemsBySort = (questions?: { sort?: number }[]) => {
  const result = [...(questions ?? [])];
  result?.sort(({ sort: prev }, { sort: next }) => {
    return (prev ?? 0) - (next ?? 0);
  });
  return result;
};

export const sortCahceItems = (
  draft: { id: number; sort?: number; title: string }[],
  id: number,
  patch: { sort?: number }
) => {
  //console.log('sortCahceItems', draft.map(({ id, title, sort }) => ({ id, title, sort })), id, patch);
  if (draft.find(item => item.id === id)?.sort === patch.sort) return;
  let start = false;
  let shift = -1;
  let shiftSort = 0;
  for (let i = 0; i < draft.length; i++) {
    const item = draft[i];
    if (item.id === id) {
      shiftSort = 1;
    }

    if (item.sort === ((patch.sort ?? 0) + shiftSort) || item.id === id) {
      if (!start && item.sort === patch.sort) {
        shift = 1;
      }

      start = !start;

    }

    if (start) {
      //console.log('sortCahceItems_start', { id: item.id, title: item.title, sort: item.sort, i: i + shift, shift });
      Object.assign(item, { sort: item.id === id ? patch.sort : i + shift });
    }
  }

  //console.log('sortCahceItems_end', draft.map(({ id, title, sort }) => ({ id, title, sort })));
};

export const questionsApi = createApi({
  reducerPath: "questionsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Questions"],
  endpoints: (builder) => ({
    getQuestionsByQuizId: builder.query<QuestionInfo[], number>({
      query: (quizId) => ({
        url: `quiz/${quizId}/question_list/`,
        method: "GET",
      }),
      transformResponse: (questions?: QuestionInfo[]) =>
        sortItemsBySort(questions) as QuestionInfo[],
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "Questions", id } as const)),
            { type: "Questions", id: "LIST" },
          ]
          : [{ type: "Questions", id: "LIST" }],
    }),
    getQuestionById: builder.query<QuestionInfo, number>({
      query: (id) => ({ url: `question/${id}/`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "Questions", id }],
    }),
    createQuestion: builder.mutation<QuestionInfo, Omit<QuestionInfo, "id">>({
      query: (data) => ({ url: "question/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: created } = await queryFulfilled;
          const [getQuestionById] = updateCacheQuestion(created);
          dispatch(getQuestionById);
          dispatch(
            questionsApi.util.updateQueryData(
              "getQuestionsByQuizId",
              created.quiz,
              (draft: QuestionInfo[]) => {
                draft.push(created);
                //console.log([...draft.map((item) => ({ ...item }))]);
                //Object.assign(draft, sortItemsBySort(draft));
              }
            )
          );
        } catch {
          dispatch(
            questionsApi.util.invalidateTags([
              { type: "Questions", id: "LIST" },
            ])
          );
        }
      },
      invalidatesTags: [{ type: "Questions", id: "LIST" }],
    }),
    copyQuestion: builder.mutation<QuestionInfo, number>({
      query: (id) => ({ url: "question/copy/", method: "POST", data: { id } }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: created } = await queryFulfilled;
          const [getQuestionById] = updateCacheQuestion(created);
          dispatch(getQuestionById);
          dispatch(
            questionsApi.util.updateQueryData(
              "getQuestionsByQuizId",
              created.quiz,
              (draft: QuestionInfo[]) => {
                draft.push(created);
                //console.log([...draft.map((item) => ({ ...item }))]);
                //Object.assign(draft, sortItemsBySort(draft));
              }
            )
          );
        } catch {
          dispatch(
            questionsApi.util.invalidateTags([
              { type: "Questions", id: "LIST" },
            ])
          );
        }
      },
      invalidatesTags: [{ type: "Questions", id: "LIST" }],
    }),
    updateQuestion: builder.mutation<
      QuestionInfo,
      PartialBy<QuestionInfo, "id" | "quiz">
    >({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `question/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted(
        { id, quiz, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const [getQuestionById, getQuestionsByQuizId] = updateCacheQuestion({
          id,
          quiz,
          ...patch,
        });
        let patchResult = dispatch(getQuestionById);
        let patchListResult = dispatch(getQuestionsByQuizId);

        try {
          const { data: updateData } = await queryFulfilled;
          const [getQuestionById, getQuestionsByQuizId] =
            updateCacheQuestion(updateData);
          patchResult = dispatch(getQuestionById);
          patchListResult = dispatch(getQuestionsByQuizId);
          if (patch.config && (patch.config as any).multi_answers === false) {
            dispatch(questionConditionsApi.util.invalidateTags([{ type: "QuestionConditions", id: "LIST" }]));
            dispatch(resultConditionsApi.util.invalidateTags([{ type: "ResultConditions", id: "LIST" }]));
          }
        } catch {
          patchResult.undo();
          patchListResult.undo();
          dispatch(
            questionsApi.util.invalidateTags([{ type: "Questions", id }])
          );
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "Questions", id }],
    }),
    deleteQuestion: builder.mutation<void, { id: number; quiz: number }>({
      query: ({ id, quiz }) => ({ url: `question/${id}/`, method: "DELETE" }),
      async onQueryStarted({ id, quiz }, { dispatch, queryFulfilled }) {
        dispatch(
          questionsApi.util.updateQueryData(
            "getQuestionsByQuizId",
            quiz,
            (draft: QuestionInfo[]) => {
              return draft.filter((item) => item.id !== id);
            }
          )
        );
        //questionsApi.util.patchQueryData
        await queryFulfilled;
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "Questions", id },
        { type: "Questions", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetQuestionsByQuizIdQuery,
  useGetQuestionByIdQuery,
  useCreateQuestionMutation,
  useDeleteQuestionMutation,
  useUpdateQuestionMutation,
  useCopyQuestionMutation
} = questionsApi;

export const updateCacheQuestion = ({
  id,
  quiz,
  ...patch
}: PartialBy<QuestionInfo, "id" | "quiz">) => {
  return [
    questionsApi.util.updateQueryData(
      "getQuestionById",
      id,
      (draft: QuestionInfo) => {
        Object.assign(draft, patch);
      }
    ),
    questionsApi.util.updateQueryData(
      "getQuestionsByQuizId",
      quiz,
      (draft: QuestionInfo[]) => {
        if (patch.sort !== undefined) {
          sortCahceItems(draft, id, patch);
        }

        Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
        if (patch.sort !== undefined) {
          Object.assign(draft, sortItemsBySort(draft));
        }
      }
    ),
  ];
};
