import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";
import { quizzesApi } from "./quizzes";
import { tariffsApi } from "./tariffs";

export interface ProjectInfo {
  id: number;
  title: string;
  user_id: number;
}

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Projects"],
  endpoints: (builder) => ({
    getProjects: builder.query<ProjectInfo[], void>({
      query: () => ({ url: "project/", method: "GET" }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ id }) => ({ type: "Projects", id } as const)),
            { type: "Projects", id: "LIST" },
          ]
          : [{ type: "Projects", id: "LIST" }],
    }),
    getProjectById: builder.query<ProjectInfo, number>({
      query: (id) => ({ url: `project/${id}`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "Projects", id }],
    }),
    createProject: builder.mutation<
      ProjectInfo,
      Omit<ProjectInfo, "id" | "user_id">
    >({
      query: (data) => ({ url: "project/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(tariffsApi.util.invalidateTags([{ type: "TariffUserInfo", id: "LIST" }]));
      },
      invalidatesTags: () => [{ type: "Projects", id: "LIST" }],
    }),
    copyProject: builder.mutation<ProjectInfo, number>({
      query: (id) => ({ url: "project/copy/", method: "POST", data: { id } }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          quizzesApi.util.invalidateTags([{ type: "Quizzes", id: "LIST" }])
        );
        dispatch(tariffsApi.util.invalidateTags([{ type: "TariffUserInfo", id: "LIST" }]));
      },
      invalidatesTags: () => [{ type: "Projects", id: "LIST" }],
    }),
    updateProject: builder.mutation<ProjectInfo, PartialBy<ProjectInfo, "id">>({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `project/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const [getQuestionById, getQuestionsByQuizId] = updateCacheProject({
          id,
          ...patch,
        });
        const patchResult = dispatch(getQuestionById);
        const patchListResult = dispatch(getQuestionsByQuizId);

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          patchListResult.undo();
          dispatch(projectsApi.util.invalidateTags([{ type: "Projects", id }]));
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "Projects", id }],
    }),
    deleteProject: builder.mutation<void, number>({
      query: (id) => ({ url: `project/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [{ type: "Projects", id }],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useCopyProjectMutation,
} = projectsApi;

export const updateCacheProject = ({
  id,
  ...patch
}: PartialBy<ProjectInfo, "id">) => {
  return [
    projectsApi.util.updateQueryData(
      "getProjectById",
      id,
      (draft: ProjectInfo) => {
        Object.assign(draft, patch);
      }
    ),
    projectsApi.util.updateQueryData(
      "getProjects",
      undefined,
      (draft: ProjectInfo[]) => {
        Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
      }
    ),
  ];
};
