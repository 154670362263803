import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery} from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationSalesDrive {
  id: number
  quiz: number
  api_url: string
  is_enabled: boolean
  form_token: string
}

export const integrationSalesDriveApi = createApi({
  reducerPath: "integrationSalesDriveApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["IntegrationSalesDrive"],
  endpoints: (builder) => ({
    getSalesDriveByQuizId: builder.query<IntegrationSalesDrive , number>({
      query: (quizId) => ({ url: `salesdrive/${quizId}/`, method: "GET" }),
      providesTags: (result, error, id) => {
        return [{ type: "IntegrationSalesDrive", id }];
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          if (err.error && err.error.status === 404) {
            dispatch(integrationSalesDriveApi.util.upsertQueryData('getSalesDriveByQuizId', arg, null as any));
          }
        }
      },
    }),
    createSalesDrive: builder.mutation<
      IntegrationSalesDrive,
      Omit<IntegrationSalesDrive, "id">
    >({
      query: (data) => ({ url: "salesdrive/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(integrationSalesDriveApi.util.invalidateTags([{ type: "IntegrationSalesDrive", id: data.quiz }]));
      },
    }),
    updateSalesDrive: builder.mutation<
      IntegrationSalesDrive,
      PartialBy<IntegrationSalesDrive, "quiz">
    >({
      query: (data) => {
        const { id, quiz, ...body } = data;
        return { url: `salesdrive/${quiz}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(updateCacheSalesDrive({ id, quiz, ...patch }));
        try {
          const result = await queryFulfilled;
          dispatch(updateCacheSalesDrive({ ...result.data }))
        } catch {
          patchResult.undo();
          dispatch(
            integrationSalesDriveApi.util.invalidateTags([{ type: "IntegrationSalesDrive", id: quiz }])
          );
        }
      },
    }),
    deleteSalesDrive: builder.mutation<void, number>({
      query: (quizId) => ({ url: `salesdrive/${quizId}/`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => {
        return  [{ type: "IntegrationSalesDrive", id }];
       },
    }),
  }),
});

export const {
  useGetSalesDriveByQuizIdQuery,
  useCreateSalesDriveMutation,
  useUpdateSalesDriveMutation,
  useDeleteSalesDriveMutation,

} = integrationSalesDriveApi;

export const updateCacheSalesDrive = ({
  id,
  quiz,
  ...patch
}: PartialBy<IntegrationSalesDrive, "quiz">) => {
  return integrationSalesDriveApi.util.updateQueryData(
    "getSalesDriveByQuizId",
    quiz,
    (draft: Partial<IntegrationSalesDrive>) => {
      Object.assign(draft, patch);
    }
  );
};
