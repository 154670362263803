import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface QuestionConfig {}

export interface AnswerInfo {
  id: number;
  answer: string;
  count_points?: number;
  image?: string | null;
  is_true: boolean;
  datetime?: string;
  question: number;
  emoji?: string | null;
}

export const answersApi = createApi({
  reducerPath: "answersApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Answers"],
  endpoints: (builder) => ({
    getAnswersByQuestionId: builder.query<AnswerInfo[], number>({
      query: (questionId) => ({
        url: `question/${questionId}/answer_list/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Answers", id } as const)),
              { type: "Answers", id: "LIST" },
            ]
          : [{ type: "Answers", id: "LIST" }],
    }),
    // getQuestionById: builder.query<AnswerInfo, number>({
    //   query: (id) => ({ url: `question/${id}`, method: "GET" }),
    //   providesTags: (result, error, id) => [{ type: "Answers", id }],
    // }),
    createAnswer: builder.mutation<AnswerInfo, Omit<AnswerInfo, "id">>({
      query: (data) => ({ url: "answer/", method: "POST", data }),
      invalidatesTags: [{ type: "Answers", id: "LIST" }],
    }),
    updateAnswer: builder.mutation<
      AnswerInfo,
      PartialBy<AnswerInfo, "id" | "question">
    >({
      query: (data) => {
        const { id, question, ...body } = data;
        return { url: `answer/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted(
        { id, question, ...patch },
        { dispatch, queryFulfilled }
      ) {
        const patchListResult = dispatch(
          updateCacheAnswer({ id, question, ...patch })
        );

        try {
          await queryFulfilled;
        } catch {
          patchListResult.undo();
          dispatch(answersApi.util.invalidateTags([{ type: "Answers", id }]));
        }
      },
      // invalidatesTags: (result, error, { id }) => [{ type: "Answers", id }],
    }),
    updateAnswerImage: builder.mutation<
      AnswerInfo,
      { id: number; formData: FormData }
    >({
      query: (data) => {
        return {
          url: `answer/${data.id}/`,
          method: "PATCH",
          data: data.formData,
        };
      },
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateCacheAnswer({ ...data }));
        } catch {
          dispatch(answersApi.util.invalidateTags([{ type: "Answers", id }]));
        }
      },

      //invalidatesTags: (result, error, { id }) => [{ type: "Answers", id }],
    }),
    deleteAnswer: builder.mutation<void, number>({
      query: (id) => ({ url: `answer/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [{ type: "Answers", id }],
    }),
  }),
});

export const {
  useGetAnswersByQuestionIdQuery,
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useUpdateAnswerMutation,
  useUpdateAnswerImageMutation,
} = answersApi;

export const updateCacheAnswer = ({
  id,
  question,
  ...patch
}: PartialBy<AnswerInfo, "id" | "question">) => {
  return answersApi.util.updateQueryData(
    "getAnswersByQuestionId",
    question,
    (draft: AnswerInfo[]) => {
      Object.assign(draft.find((item) => item.id === id) ?? {}, patch);
    }
  );
};
