import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery} from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";



export interface IntegrationWebhookInfo {
  id: number
  quiz: number
  link: string
  is_enabled: boolean
  password_hash?: string | null
}

export const integrationWebhooksApi = createApi({
  reducerPath: "integrationWebhooksApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["IntegrationWebhooks"],
  endpoints: (builder) => ({
    getWebhookByQuizId: builder.query<IntegrationWebhookInfo , number>({
      query: (quizId) => ({ url: `webhook-integration/${quizId}/`, method: "GET" }),
      providesTags: (result, error, id) => {
        return [{ type: "IntegrationWebhooks", id }];
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          if (err.error && err.error.status === 404) {
            dispatch(integrationWebhooksApi.util.upsertQueryData('getWebhookByQuizId', arg, null as any));
          }
        }
      },
    }),
    createWebhook: builder.mutation<
      IntegrationWebhookInfo,
      Omit<IntegrationWebhookInfo, "id">
    >({
      query: (data) => ({ url: "webhook-integration/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(integrationWebhooksApi.util.invalidateTags([{ type: "IntegrationWebhooks", id: data.quiz }]));
      },
      //invalidatesTags: [{ type: "IntegrationWebhooks", id: "LIST" }],
    }),
    updateWebhook: builder.mutation<
      IntegrationWebhookInfo,
      PartialBy<IntegrationWebhookInfo, "quiz">
    >({
      query: (data) => {
        const { id, quiz, ...body } = data;
        return { url: `webhook-integration/${quiz}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(updateCacheWebhook({ id, quiz, ...patch }));
        try {
          const result = await queryFulfilled;
          dispatch(updateCacheWebhook({ ...result.data }))
        } catch {
          patchResult.undo();
          dispatch(
            integrationWebhooksApi.util.invalidateTags([{ type: "IntegrationWebhooks", id: quiz }])
          );
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "QuizConfigs", id }],
    }),
    deleteWebhook: builder.mutation<void, number>({
      query: (quizId) => ({ url: `webhook-integration/${quizId}/`, method: "DELETE" }),
      // async onQueryStarted(quizId, { dispatch, queryFulfilled }) {
      //   await queryFulfilled;

      //   dispatch(integrationWebhooksApi.util.invalidateTags([{ type: "IntegrationWebhooks", id: quizId }]));
      // },
      invalidatesTags: (result, error, id) => {
        return  [{ type: "IntegrationWebhooks", id }];
       },
    }),
  }),
});

export const {
  useGetWebhookByQuizIdQuery,
  useCreateWebhookMutation,
  useUpdateWebhookMutation,
  useDeleteWebhookMutation,

} = integrationWebhooksApi;

export const updateCacheWebhook = ({
  id,
  quiz,
  ...patch
}: PartialBy<IntegrationWebhookInfo, "quiz">) => {
  return integrationWebhooksApi.util.updateQueryData(
    "getWebhookByQuizId",
    quiz,
    (draft: Partial<IntegrationWebhookInfo>) => {
      Object.assign(draft, patch);
    }
  );
};
