import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { resetPassword } from "redux/auth/operations";
import { getIsLoading } from "redux/auth/selector";

import { useAppDispatch } from "redux/store";
import { PublicLayout } from "components/PublicLayout";

const initialValues = {
  email: "",
};

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

const ForgotPassword: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const isLoading = useSelector(getIsLoading);
  const [isSend, setIsSend] = useState(false);

  return (
    <PublicLayout>
      <div className="login-form_box mb-8">
        <h1 className="login-form_title text-center">{t("forgotPassword.title")}</h1>
        <h2 className="login-form_title-second text-center">
          {t("forgotPassword.titleSecond")}
        </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={ForgotPasswordSchema}
          onSubmit={async ({ email }) => {
            await dispatch(
              resetPassword({ email }) as unknown as AnyAction
            );
            setIsSend(true);
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            touched, errors
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="login-form"
                id="kt_login_singin_form"
                action=""
                noValidate={true}
              >
                <div>
                  <label
                  className={
                    errors.email && touched.email
                      ? "form-label error"
                      : "form-label"
                  }>
                  {t("forgotPassword.email")}
                  </label>
                  <input
                    className={
                      errors.email && touched.email
                        ? "form-control error"
                        : "form-control"
                    }
                    type="email"
                    name="email"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                  <ErrorMessage
                    name="email"
                    render={(message) => (
                        <div
                          data-field="email"
                          data-validator="notEmpty"
                          className="text-errors"
                        >
                          {message}
                        </div>
                    )}
                  ></ErrorMessage>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  id="kt_login_singin_form_submit_button"
                  className="btn btn-sm btn-primary font-weight-bolder font-size-h6 mt-2 w-100" //mb-5
                >
                  {t("forgotPassword.button")}
                </button>
                {isSend && <p className="fw-normal text-black fs-8 mt-4">{t("forgotPassword.text")}</p>}
              </form>
            );
          }}
        </Formik>
      </div>
    </PublicLayout>
  );
};

export default ForgotPassword;