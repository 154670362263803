import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { RootState } from "redux/store";
import {
  AccessToken,
  LoginData,
  RefreshToken,
  RegistrationData,
  ResetPassword,
  SetNewPassword,
  TokenData,
} from "./models";
import { getTokens } from "./selector";
import i18n from "../../i18n";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export const login = createAsyncThunk<TokenData, LoginData, { rejectValue: string }>(
  "auth/login",
  async (data: LoginData, { rejectWithValue }) => {
    try {
      return (
        await axios.post<TokenData>(
          "token/",
          data /* { withCredentials: true } */
        )
      ).data;
    } catch (error) {
      return rejectWithValue(
        i18n.t("login.invalidPasswordLogin") /* "Не вірний пароль і/або логін" */
      );
    }
  });

export const registration = createAsyncThunk<TokenData, RegistrationData, { rejectValue: string }>(
  "auth/registration",
  async (data: RegistrationData, { rejectWithValue }) => {
    try {
      return (
        await axios.post<TokenData>(
          "register/",
          data, { withCredentials: true }
        )
      ).data;
    } catch (error: any | AxiosError<{ [key: string]: [] }, any>) {
      console.log(error);
      return rejectWithValue(
        error?.response
          ?.data /* i18n.t('login.invalidPasswordLogin') */ /* "Не вірний пароль і/або логін" */
      );
    }
  });

export const refreshToken = createAsyncThunk<AccessToken, void, { rejectValue: string }>(
  "auth/refresh",
  async (_, { getState, rejectWithValue }) => {
    try {
      const data = {
        refresh: getTokens(getState() as RootState).refresh as string,
      } as RefreshToken;
      return (await axios.post<AccessToken>("token/refresh/", data)).data;
    } catch (error) {
      return rejectWithValue("Refresh token is not valid");
    }
  });

export const resetPassword = createAsyncThunk<unknown, ResetPassword, { rejectValue: string }>(
  "auth/resetPassword",
  async (data, { getState, rejectWithValue }) => {
    try {
      return (await axios.post<unknown>("password_reset/", data)).data;
    } catch (error) {
      return rejectWithValue("Email is not valid");
    }
  });

export const setNewPassword = createAsyncThunk<unknown, SetNewPassword, { rejectValue: string }>(
  "auth/setNewPassword",
  async (data, { getState, rejectWithValue }) => {
    try {
      return (await axios.post<unknown>("password_reset/confirm/", data)).data;
    } catch (error: any) {
      if (error.request.status === 404) {
        return rejectWithValue("Token is not valid");
      } else
        return rejectWithValue("Token is not valid");
    }
  });
