import { FC, Suspense} from "react";
import { Outlet } from "react-router-dom";

const Layout: FC = () => {

  return (
    <div className="d-flex flex-column flex-root app-root">
      <div className="app-page flex-column flex-column-fluid">
        <Suspense fallback={null}>
          <Outlet></Outlet>
        </Suspense>
      </div>
    </div>
  );
};

export { Layout };
