import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { getIsLogin } from "redux/auth/selector";

type Props = {
  component: ReactElement;
  redirectTo?: string;
};

export const PrivateRoute: FC<Props> = ({
  component: Component,
  redirectTo = "/",
}) => {
  const isLogin = useSelector(getIsLogin);
  const location = useLocation();
  const encodeUrl = encodeURI(
    (location.pathname !== "/logout" ? location.pathname : "/")
  );
  let params: string[] = [];
  new URLSearchParams(location.search).forEach((value, key) => {
    params.push(key + "=" + value);
  });
  params.push("redirectUrl=" + encodeUrl);
  //console.log(params.join("&"))
  return !isLogin ? (
    <Navigate to={redirectTo + `?${params.join("&")}`} />
  ) : (
    Component
  );
};
