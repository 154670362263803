import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { quizzesApi } from "./quizzes";

export interface TemplateModelInfo {
  count_pages: number;
  objects: TemplateInfo[];
}

export interface TemplateInfo {
  id: number;
  description: string;
  description_short_1: string;
  description_short_2: string;
  image: string;
  language_group: number;
  meta_description: string;
  meta_title: string;
  quiz: number;
  quiz_template_theme: number;
  quiz_template_type: number[];
  title: string;
}

export interface TemplateQueryParams {
  lang?: string;
  quiz_template_type?: number;
  quiz_template_theme?: number;
  search_word?: string;
  limit?: number; // = 10;
  page?: number; // = 1;
}

export interface TemplateType {
  id: number;
  title?: string;
  title_en?: string;
  title_uk?: string;
}

export interface TemplateTheme extends TemplateType {
  is_published: boolean;
}

interface CopyInfo {
  quiz: number;
  project: number;
}

export const templatesApi = createApi({
  reducerPath: "templatesApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTemplates: builder.query<TemplateModelInfo, TemplateQueryParams>({
      query: (params) => ({
        url: "quiz-templates/",
        method: "GET",
        params, //: JSON.stringify(params),
      }),
      // transformResponse: (rawResult: TemplateInfo[]) => {
      //   const [result] = rawResult;
      //   return result;
      // },
    }),
    copyTemplate: builder.mutation<any, CopyInfo>({
      query: (data) => ({ url: "copy-template/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: created } = await queryFulfilled;
        console.log(created);
        dispatch(
          quizzesApi.util.invalidateTags([{ type: "Quizzes", id: "LIST" }])
        );
      },
    }),

    getTemplateTypes: builder.query<TemplateType[], void>({
      query: () => ({ url: "quiztemplatetype/", method: "GET" }),
    }),
    getTemplateThemes: builder.query<TemplateTheme[], void>({
      query: () => ({ url: "quiztemplatetheme/", method: "GET" }),
    }),
  }),
});

export const {
  useGetTemplatesQuery,
  useGetTemplateTypesQuery,
  useGetTemplateThemesQuery,
  useCopyTemplateMutation,
} = templatesApi;
