import { createSlice } from "@reduxjs/toolkit";

export interface AppState {
  isShowDevices: boolean;
  showDeviceType: DeviceType;
}
export enum DeviceType {
  Desktop = 0,
  Mobile = 1,
}

const initialState: AppState = {
  isShowDevices: true,
  showDeviceType: DeviceType.Desktop,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsShowDevices(state, { payload }) {
      state.isShowDevices = payload;
    },
    setShowDeviceType(state, { payload }) {
      state.showDeviceType = payload;
    },
  },
});

export const { setIsShowDevices, setShowDeviceType } = appSlice.actions;

export default appSlice.reducer;
