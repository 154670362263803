import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationPipedrive {
    id: number
    quiz: number
    is_enabled: boolean
    api_token: string
}

export const integrationPipedriveApi = createApi({
    reducerPath: "integrationPipedriveApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationPipedrive"],
    endpoints: (builder) => ({
        getPipedriveByQuizId: builder.query<IntegrationPipedrive, number>({
            query: (quizId) => ({ url: `pipedrive/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationPipedrive", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationPipedriveApi.util.upsertQueryData('getPipedriveByQuizId', arg, null as any));
                    }
                }
            },
        }),
        createPipedrive: builder.mutation<
            IntegrationPipedrive,
            Omit<IntegrationPipedrive, "id">
        >({
            query: (data) => ({ url: "pipedrive/", method: "POST", data }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                const { data } = await queryFulfilled;
                dispatch(integrationPipedriveApi.util.invalidateTags([{ type: "IntegrationPipedrive", id: data.quiz }]));
            },
        }),
        updatePipedrive: builder.mutation<
            IntegrationPipedrive,
            PartialBy<IntegrationPipedrive, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `pipedrive/${quiz}/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheSalesDrive({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheSalesDrive({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationPipedriveApi.util.invalidateTags([{ type: "IntegrationPipedrive", id: quiz }])
                    );
                }
            },
        }),
        deletePipedrive: builder.mutation<void, number>({
            query: (quizId) => ({ url: `pipedrive/${quizId}/`, method: "DELETE" }),
            invalidatesTags: (result, error, id) => {
                return [{ type: "IntegrationPipedrive", id }];
            },
        }),
    }),
});

export const {
    useGetPipedriveByQuizIdQuery,
    useCreatePipedriveMutation,
    useUpdatePipedriveMutation,
    useDeletePipedriveMutation,

} = integrationPipedriveApi;

export const updateCacheSalesDrive = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationPipedrive, "quiz">) => {
    return integrationPipedriveApi.util.updateQueryData(
        "getPipedriveByQuizId",
        quiz,
        (draft: Partial<IntegrationPipedrive>) => {
            Object.assign(draft, patch);
        }
    );
};
