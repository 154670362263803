import { close_modal } from "assets/media/icons/custom";
import clsx from "clsx";
import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import SVG from "react-inlinesvg";

const modalRoot = document.querySelector<Element>("#modal");

type Props = {
  children: ReactNode;
  onClose: () => void;
  isShow: boolean;
  modalClassName?: string;
  contentClassName?: string;
};

export const Modal: FC<Props> = ({
  children,
  onClose,
  isShow,
  modalClassName,
  contentClassName,
}) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  return createPortal(
    <>
      <div
        style={isShow ? {} : { width: 0, height: 0 }}
        className={`modal-backdrop fade ${isShow ? "show" : ""}`}
      ></div>
      <div
        className={`modal fade ${isShow ? "show modal-event-none" : ""}`}
        style={{ display: "block", ...(isShow ? {} : { width: 0, height: 0 }) }}
        //style={{ display: "block" }}
        onClick={onClose}
      >
        <div
          className={clsx(`modal-dialog modal-dialog-centered`, modalClassName)}
        >
          <div
            className={clsx(`modal-content`, contentClassName)}
            onClick={handleClick}
          >
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onClose}
            >
              <SVG
                src={close_modal}
                width={14}
                height={14}
                className="ki ki-close"
              />
            </button>
            {children}
          </div>
        </div>
      </div>
    </>,
    modalRoot as Element
  );
};
