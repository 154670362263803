import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
// import 'redux-thunk/extend-redux'
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { login } from "redux/auth/operations";
import { /* getErrorMessage, */ getIsLoading } from "redux/auth/selector";

import { useAppDispatch } from "redux/store";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PublicLayout } from "components/PublicLayout";

const initialValues = {
  email: "",
  password: "",
};

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

export const Login: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const isLoading = useSelector(getIsLoading);
  //const errorMessage = useSelector(getErrorMessage);
  const navigate = useNavigate();
  let location = useLocation();

  return (
    <PublicLayout>
      <div className="login-form_box mb-8">
        <h1 className="login-form_title">{t("login.title")}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={LoginSchema}
          onSubmit={async ({ email, password }, { setErrors }) => {
            const res = await dispatch(
              login({ email, password }) as unknown as AnyAction
            );
            if (res.error) setErrors({ password: res.payload });
            //setErrors({password: "Пароль может содержать только латинские буквы и цифры"})
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            touched,
            errors,
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="login-form"
                id="kt_login_singin_form"
                action=""
                noValidate={true}
              >
                <div>
                  <label
                    className={
                      errors.email && touched.email
                        ? "form-label error"
                        : "form-label"
                    }
                  >
                    {t("registration.yourEmail")}
                  </label>
                  <input
                    className={
                      errors.email && touched.email
                        ? "form-control error"
                        : "form-control"
                    }
                    type="email"
                    name="email"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />

                  <ErrorMessage
                    name="email"
                    render={(message) => (
                      <div
                        data-field="email"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <div>
                  <label
                    className={
                      errors.password && touched.password
                        ? "form-label error"
                        : "form-label"
                    }
                  >
                    {t("registration.enterPassword")}
                  </label>
                  <input
                    className={
                      errors.password && touched.password
                        ? "form-control error"
                        : "form-control"
                    }
                    type="password"
                    name="password"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  <ErrorMessage
                    name="password"
                    render={(message) => (
                      <div
                        data-field="password"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  id="kt_login_singin_form_submit_button"
                  className="btn btn-sm btn-primary font-weight-bolder font-size-h6 mt-2 mb-4 w-100" //mb-5
                >
                  {t("registration.loginBtn")}
                </button>

                {/* <h2 className="login-form_title-second">{t("login.text")}</h2> */}
                {/* <button className="form-button btn btn-sm bg-body btn-light-primary w-100" type="button">
                <span>
                  <SVG className="" src={google} width={16} height={16} />
                </span>
                <span>{t("registration.googleBtn")}</span>
                </button> */}

                <Link className="d-block login-form_title-second mb-0" to={{ ...location, pathname: "/forgot-password" }}>{t("login.forgotPassword")}</Link>
              </form>
            );
          }}
        </Formik>
      </div>

      <div className="login-form_box">
        <h2 className="login-form_title-second text-center">
          {t("login.titleSecond")}
        </h2>
        <button
          type="submit"
          disabled={isLoading}
          id="kt_login_singin_form_submit_button"
          className="btn btn-sm btn-primary font-weight-bolder font-size-h6 w-100"
          onClick={() => navigate("/registration")}
        >
          {t("registration.registerBtn")}
        </button>
      </div>
    </PublicLayout>
  );
};
