import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationSalesforce {
    id: number,
    quiz: number,
    is_enabled: boolean
}

export interface IntegrationSalesforceAoutUrl {
    auth_url: "string"
}

export const integrationSalesforceApi = createApi({
    reducerPath: "integrationSalesforceApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationSalesforce"],
    endpoints: (builder) => ({
        getSalesforceByQuizId: builder.query<IntegrationSalesforce, number>({
            query: (quizId) => ({ url: `salesforce/${quizId}/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationSalesforce", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationSalesforceApi.util.upsertQueryData('getSalesforceByQuizId', arg, null as any));
                    }

                    if (err.error && err.error.status === 404) {
                        dispatch(integrationSalesforceApi.endpoints.getSalesforceUrlByQuizId.initiate(arg));
                    }
                }
            },
        }),
        getSalesforceUrlByQuizId: builder.query<IntegrationSalesforceAoutUrl, number>({
            query: (quizId) => ({ url: `salesforce/auth-url/${quizId}`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationSalesforce", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationSalesforceApi.util.upsertQueryData('getSalesforceUrlByQuizId', arg, null as any));
                    }
                }
            },
        }),
        updateSalesforce: builder.mutation<
            IntegrationSalesforce,
            PartialBy<IntegrationSalesforce, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `salesforce/${quiz}/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheKeyCrm({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheKeyCrm({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationSalesforceApi.util.invalidateTags([{ type: "IntegrationSalesforce", id: quiz }])
                    );
                }
            },
        }),
        deleteSalesforce: builder.mutation<void, number>({
            query: (quizId) => ({ url: `salesforce/${quizId}/`, method: "DELETE" }),
            invalidatesTags: (result, error, id) => {
              return  [{ type: "IntegrationSalesforce", id }];
             },
        }),
    }),
});

export const {
    useGetSalesforceByQuizIdQuery,
    useGetSalesforceUrlByQuizIdQuery,
    useUpdateSalesforceMutation,
    useDeleteSalesforceMutation
} = integrationSalesforceApi;

export const updateCacheKeyCrm = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationSalesforce, "quiz">) => {
    return integrationSalesforceApi.util.updateQueryData(
        "getSalesforceByQuizId",
        quiz,
        (draft: Partial<IntegrationSalesforce>) => {
            Object.assign(draft, patch);
        }
    );
};
