import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";

export interface LanguageInfo {
  id: number;
  title: string;
  code: string;
  landing: boolean;
  admin_panel: boolean;
  quiz: boolean;
}

export const languagesApi = createApi({
  reducerPath: "languagesApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getLanguages: builder.query<LanguageInfo[], void>({
      query: () => ({ url: "languagelist/", method: "GET" }),
    }),
  }),
});

export const { useGetLanguagesQuery } = languagesApi;