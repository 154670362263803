import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../redux/api/userInfo";


export const useGetLanguage = () => {
    const { data } = useGetUserInfoQuery();
    const [searchParams] = useSearchParams();
    const { i18n } = useTranslation();

    return data?.language_code ?? searchParams.get('lang') ?? i18n.language ?? "en";
}