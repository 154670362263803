import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationZoho {
    id: number,
    quiz: number,
    is_enabled: boolean
}

export interface IntegrationZohoAoutUrl {
    auth_url: "string"
}

export const integrationZohoApi = createApi({
    reducerPath: "integrationZohoApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["IntegrationZoho"],
    endpoints: (builder) => ({
        getZohoByQuizId: builder.query<IntegrationZoho, number>({
            query: (quizId) => ({ url: `zoho/integration/${quizId}/toggle/`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationZoho", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationZohoApi.util.upsertQueryData('getZohoByQuizId', arg, null as any));
                    }

                    if (err.error && err.error.status === 404) {
                        dispatch(integrationZohoApi.endpoints.getZohoUrlByQuizId.initiate(arg));
                    }
                }
            },
        }),
        getZohoUrlByQuizId: builder.query<IntegrationZohoAoutUrl, number>({
            query: (quizId) => ({ url: `zoho/auth-url/${quizId}`, method: "GET" }),
            providesTags: (result, error, id) => {
                return [{ type: "IntegrationZoho", id }];
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    await queryFulfilled;
                } catch (err: any) {
                    if (err.error && err.error.status === 404) {
                        dispatch(integrationZohoApi.util.upsertQueryData('getZohoUrlByQuizId', arg, null as any));
                    }
                }
            },
        }),
        updateZoho: builder.mutation<
            IntegrationZoho,
            PartialBy<IntegrationZoho, "quiz">
        >({
            query: (data) => {
                const { id, quiz, ...body } = data;
                return { url: `zoho/integration/${quiz}/toggle/`, method: "PATCH", data: body };
            },
            async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateCacheKeyCrm({ id, quiz, ...patch }));
                try {
                    const result = await queryFulfilled;
                    dispatch(updateCacheKeyCrm({ ...result.data }))
                } catch {
                    patchResult.undo();
                    dispatch(
                        integrationZohoApi.util.invalidateTags([{ type: "IntegrationZoho", id: quiz }])
                    );
                }
            },
        }),
        deleteZoho: builder.mutation<void, number>({
            query: (quizId) => ({ url: `zoho/integration/${quizId}/delete/`, method: "DELETE" }),
            invalidatesTags: (result, error, id) => {
              return  [{ type: "IntegrationZoho", id }];
             },
        }),
    }),
});

export const {
    useGetZohoByQuizIdQuery,
    useGetZohoUrlByQuizIdQuery,
    useUpdateZohoMutation,
    useDeleteZohoMutation
} = integrationZohoApi;

export const updateCacheKeyCrm = ({
    id,
    quiz,
    ...patch
}: PartialBy<IntegrationZoho, "quiz">) => {
    return integrationZohoApi.util.updateQueryData(
        "getZohoByQuizId",
        quiz,
        (draft: Partial<IntegrationZoho>) => {
            Object.assign(draft, patch);
        }
    );
};
