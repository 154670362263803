import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { ConditionInfo } from "./questionConditions";

export interface ResultConditionInfo extends ConditionInfo {
  result: number
}

export const resultConditionsApi = createApi({
  reducerPath: "resultConditionsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["ResultConditions"],
  endpoints: (builder) => ({
    getResultConditionsByResultId: builder.query<
    ResultConditionInfo[],
      number
    >({
      query: (resultId) => ({
        url: `resultconditionlist/${resultId}/`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }) => ({ type: "ResultConditions", id } as const)
              ),
              { type: "ResultConditions", id: "LIST" },
            ]
          : [{ type: "ResultConditions", id: "LIST" }],
    }),
    getResultConditionById: builder.query<ResultConditionInfo, number>({
      query: (id) => ({ url: `resultcondition/${id}`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "ResultConditions", id }],
    }),
    createResultCondition: builder.mutation<
    ResultConditionInfo,
      Omit<ResultConditionInfo, "id">
    >({
      query: (data) => ({ url: "resultcondition/", method: "POST", data }),
      invalidatesTags: [{ type: "ResultConditions", id: "LIST" }],
    }),
    updateResultCondition: builder.mutation<
    ResultConditionInfo,
      Partial<ResultConditionInfo>
    >({
      query: (data) => {
        console.log();
        const { id, ...body } = data;
        return {
          url: `resultcondition/${data.id}/`,
          method: "PATCH",
          data: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "ResultConditions", id },
      ],
    }),
    deleteResultCondition: builder.mutation<void, number>({
      query: (id) => ({ url: `resultcondition/${id}`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => [
        { type: "ResultConditions", id },
      ],
    }),
  }),
});

export const {
  useGetResultConditionsByResultIdQuery,
  useGetResultConditionByIdQuery,
  useCreateResultConditionMutation,
  useDeleteResultConditionMutation,
  useUpdateResultConditionMutation,
} = resultConditionsApi;
