import { FC } from "react";
//import { useNavigate } from "react-router-dom";
import { authAction } from "redux/auth/slice";
import { resetApiState, useAppDispatch } from "redux/store";

const Logout: FC = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  // navigate("/login");
  resetApiState();
  dispatch(authAction.logout());

  return <></>;
};

export default Logout;
