import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery} from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export interface IntegrationKeyCrm {
  id: number
  quiz: number
  api_token: string,
  source: string,
  is_enabled: boolean
}

export const integrationKeyCrmApi = createApi({
  reducerPath: "integrationKeyCrmApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["IntegrationKeyCrm"],
  endpoints: (builder) => ({
    getKeyCrmByQuizId: builder.query<IntegrationKeyCrm , number>({
      query: (quizId) => ({ url: `keycrm/${quizId}/`, method: "GET" }),
      providesTags: (result, error, id) => {
        return [{ type: "IntegrationKeyCrm", id }];
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          await queryFulfilled;
        } catch (err: any) {
          if (err.error && err.error.status === 404) {
            dispatch(integrationKeyCrmApi.util.upsertQueryData('getKeyCrmByQuizId', arg, null as any));
          }
        }
      },
    }),
    createKeyCrm: builder.mutation<
      IntegrationKeyCrm,
      Omit<IntegrationKeyCrm, "id">
    >({
      query: (data) => ({ url: "keycrm/", method: "POST", data }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(integrationKeyCrmApi.util.invalidateTags([{ type: "IntegrationKeyCrm", id: data.quiz }]));
      },
    }),
    updateKeyCrm: builder.mutation<
      IntegrationKeyCrm,
      PartialBy<IntegrationKeyCrm, "quiz">
    >({
      query: (data) => {
        const { id, quiz, ...body } = data;
        return { url: `keycrm/${quiz}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, quiz, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(updateCacheKeyCrm({ id, quiz, ...patch }));
        try {
          const result = await queryFulfilled;
          dispatch(updateCacheKeyCrm({ ...result.data }))
        } catch {
          patchResult.undo();
          dispatch(
            integrationKeyCrmApi.util.invalidateTags([{ type: "IntegrationKeyCrm", id: quiz }])
          );
        }
      },
    }),
    deleteKeyCrm: builder.mutation<void, number>({
      query: (quizId) => ({ url: `keycrm/${quizId}/`, method: "DELETE" }),
      invalidatesTags: (result, error, id) => {
        return  [{ type: "IntegrationKeyCrm", id }];
       },
    }),
  }),
});

export const {
  useGetKeyCrmByQuizIdQuery,
  useCreateKeyCrmMutation,
  useUpdateKeyCrmMutation,
  useDeleteKeyCrmMutation,

} = integrationKeyCrmApi;

export const updateCacheKeyCrm = ({
  id,
  quiz,
  ...patch
}: PartialBy<IntegrationKeyCrm, "quiz">) => {
  return integrationKeyCrmApi.util.updateQueryData(
    "getKeyCrmByQuizId",
    quiz,
    (draft: Partial<IntegrationKeyCrm>) => {
      Object.assign(draft, patch);
    }
  );
};
