import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { AnyAction } from "redux";
import { getIsLoading } from "redux/auth/selector";

import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { PublicLayout } from "components/PublicLayout";
import i18n from "i18n";
import { setNewPassword } from "redux/auth/operations";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required(i18n.t("errors.required"))
    .min(8, i18n.t("errors.minLength"))
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)/, i18n.t("errors.mustNotOnlyNumbers")),
  confirmPassword: Yup.string()
    .required(i18n.t("errors.required"))
    .oneOf([Yup.ref("newPassword"), null], i18n.t("errors.repeatPassword")),
});

const ResetPassword: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const isLoading = useSelector(getIsLoading);
  const navigate = useNavigate();
  // const query = useLocation().search;
  // const token = useMemo(()=> new URLSearchParams(query).get('token'), [query]);
  const { token } = useParams();

  return (
    <PublicLayout>
      <div className="login-form_box mb-8">
        <h1 className="login-form_title text-center">{t("resetPassword.title")}</h1>
        <Formik
          initialValues={initialValues}
          validationSchema={ResetPasswordSchema}
          onSubmit={async (
            { newPassword },
            { setErrors }
          ) => {
            if (!token) return;
            const res = await dispatch(
              setNewPassword({ password: newPassword, token }) as unknown as AnyAction
            );
            if (res.error) {
              setErrors({ newPassword: res.payload as string });
            }
            else navigate("/login");
          }}
        >
          {({
            values,
            handleSubmit,
            handleChange,
            handleBlur,
            isSubmitting,
            touched, errors
          }) => {
            return (
              <form
                onSubmit={handleSubmit}
                className="login-form"
                id="kt_login_singin_form"
                action=""
                noValidate={true}
              >
                <div>
                  <label
                    className={
                      errors.newPassword && touched.newPassword
                        ? "form-label error"
                        : "form-label"
                    }
                  >
                    {t("resetPassword.newPassword")}
                  </label>
                  <input
                    className={
                      errors.newPassword && touched.newPassword
                        ? "form-control error"
                        : "form-control"
                    }
                    type="password"
                    name="newPassword"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                  <ErrorMessage
                    name="newPassword"
                    render={(message) => (
                      <div
                        data-field="newPassword"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <div>
                  <label
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "form-label error"
                        : "form-label"
                    }
                  >
                    {t("resetPassword.confirmPassword")}
                  </label>
                  <input
                    className={
                      errors.confirmPassword && touched.confirmPassword
                        ? "form-control error"
                        : "form-control"
                    }
                    type="password"
                    name="confirmPassword"
                    required
                    autoComplete="off"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                  />
                  <ErrorMessage
                    name="confirmPassword"
                    render={(message) => (
                      <div
                        data-field="password"
                        data-validator="notEmpty"
                        className="text-errors"
                      >
                        {message}
                      </div>
                    )}
                  ></ErrorMessage>
                </div>

                <button
                  type="submit"
                  disabled={isLoading}
                  id="kt_login_singin_form_submit_button"
                  className="btn btn-sm btn-primary font-weight-bolder font-size-h6 mt-2 w-100" //mb-5
                >
                  {t("resetPassword.resetBtn")}
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    </PublicLayout>
  );
};

export default ResetPassword;