import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "utils/axiosBaseQuery";
import { PartialBy } from "utils/declare";

export const enum TriggerTypeValue {
  Icon = 1,
  Video = 2,
  Consultant = 3,
}

export interface QuizConfigInfo {
  id: number;

  type: number;

  trigger_type: TriggerTypeValue;
  icon_position: number;
  icon_trigger_text: string;
  icon_trigger_color: string;
  icon_text_color: string;
  icon_icon_type: number;
  icon_opening_time: number;
  icon_auto_opening: boolean;
  video_position: number;
  video_trigger_text: string;
  video_link: string;
  video_trigger_color: string;
  video_text_color: string;
  video_icon_type: number;
  video_play_on_hover: boolean;
  video_open_on_click: boolean;

  banner_position: number;
  banner_trigger_text: string;
  banner_trigger_description: string;
  banner_trigger_color: string;
  banner_text_color: string;
  banner_image: string | null;

  manager_position: number;
  manager_name: string;
  manager_message: string;
  manager_image: string | null;

  //Todo: need add
  manager_trigger_color: string;
  manager_text_color: string;
  manager_icon_type: number;

  popup_opening_time: number;
  popup_mobile_auto_opening: boolean;

  landing_logo: string | null;
  landing_favicon: string | null;
  landing_company_name: string;
  landing_site: string;
  landing_phone: string;
  landing_legal_information: string;
  //TODO: Need landing_domain

  body_width: number;
  body_height: number;
  body_pading: number;
  body_button_text: string;
  body_button_color: string;
  body_button_text_color: string;

  chat_position: number;
  chat_opening_time: number;
  chat_auto_opening: boolean;

  chat_color: string;
  chat_text_color: string;

  chat_button_text: string;

  quiz: number;

  integration_email: boolean;
  // Analitics
  facebook_pixel_id: string | null;
  facebook_pixel_status: boolean;

  google_tag_manager_id: string | null;
  google_tag_manager_status: boolean;

  google_analytics_id: string | null;
  google_analytics_status: boolean;

  tiktok_id: string | null;
  tiktok_status: boolean;


}

export const quizConfigsApi = createApi({
  reducerPath: "quizConfigsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["QuizConfigs"],
  endpoints: (builder) => ({
    getQuizConfigById: builder.query<QuizConfigInfo, number>({
      query: (id) => ({ url: `config/quiz/${id}`, method: "GET" }),
      providesTags: (result, error, id) => [{ type: "QuizConfigs", id }],
    }),
    createQuizConfig: builder.mutation<
      QuizConfigInfo,
      Omit<QuizConfigInfo, "id">
    >({
      query: (data) => ({ url: "config/quiz/", method: "POST", data }),
      invalidatesTags: [{ type: "QuizConfigs", id: "LIST" }],
    }),
    updateQuizConfig: builder.mutation<
      QuizConfigInfo,
      PartialBy<QuizConfigInfo, "id">
    >({
      query: (data) => {
        const { id, ...body } = data;
        return { url: `config/quiz/${data.id}/`, method: "PATCH", data: body };
      },
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(updateCacheQuizConfig({ id, ...patch }));
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
          dispatch(
            quizConfigsApi.util.invalidateTags([{ type: "QuizConfigs", id }])
          );
        }
      },
      //invalidatesTags: (result, error, { id }) => [{ type: "QuizConfigs", id }],
    }),
    updateQuizConfigImage: builder.mutation<
      QuizConfigInfo,
      { id: number; formData: FormData }
    >({
      query: (data) => {
        return {
          url: `config/quiz/${data.id}/`,
          method: "PATCH",
          data: data.formData,
        };
      },
      // async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
      //   try {
      //     const { data } = await queryFulfilled;
      //     dispatch(updateCacheQuizConfig({ ...data }));
      //   } catch {
      //     dispatch(
      //       quizConfigsApi.util.invalidateTags([{ type: "QuizConfigs", id }])
      //     );
      //   }
      // },
      invalidatesTags: (result, error, { id }) => [{ type: "QuizConfigs", id }],
    }),
    // deleteQuiz: builder.mutation<void, number>({
    //   query: (id) => ({ url: `quiz/${id}`, method: "DELETE" }),
    //   invalidatesTags: (result, error, id) => [{ type: "QuizConfigs", id }],
    // }),
  }),
});

export const {
  useGetQuizConfigByIdQuery,
  useCreateQuizConfigMutation,
  useUpdateQuizConfigMutation,
  //useDeleteQuizConfigMutation,

  useUpdateQuizConfigImageMutation,
} = quizConfigsApi;

export const updateCacheQuizConfig = ({
  id,
  ...patch
}: PartialBy<QuizConfigInfo, "id">) => {
  return quizConfigsApi.util.updateQueryData(
    "getQuizConfigById",
    id,
    (draft: Partial<QuizConfigInfo>) => {
      Object.assign(draft, patch);
    }
  );
};
